select,
input {
  border-radius: .25rem;
  border-width: 2px;
}
input[type='radio'] + label {
  color: var(--blue);
}
select {
  background-image:  url('svgs/arrow-down.svg');
  background-repeat:  no-repeat;
  background-position: calc(100% - .5rem)  center;
}

input[type='radio'] + label {
  position: relative;
  padding-left: 1.75em;
}

input[type='radio'] + label:before {
  position: absolute;
  top: -1.5px;
  left: 0;
  content: "X";
  margin: 0 .5em 0 0;
  padding: 0;
  width: 1em;
  line-height: 1em;
}

input[type='radio'] + label:before { border-radius: 50%; }

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before { 
  background: var(--teal);
}

input[type='radio']:checked + label:before { 
  color: transparent;
}

