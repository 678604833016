button,
*.btn {
  color: var(--blue);
  border-width: 2px;
  border-color: var(--blue);
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  background-color: transparent;
  border-radius: 9999px;
  text-align: center;
  padding: .5em 1.5em;
  transition: all 200ms ease;
  transform: translatez(0);
}

*.btn-bg,
button:hover,
*.btn:hover {
  background-image: linear-gradient(-134deg, var(--teal) 0%, var(--blue) 100%);
  border-color: var(--teal);
  color: white;
}

.btn-white {
  border-color: white;
  color: white;
}
