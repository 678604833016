@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?58deq9');
  src:  url('fonts/icomoon.eot?58deq9#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?58deq9') format('truetype'),
    url('fonts/icomoon.woff?58deq9') format('woff'),
    url('fonts/icomoon.svg?58deq9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-more-vertical:before {
  content: "\e908";
}
.icon-africa-regions-icon:before {
  content: "\e907";
  color: #1e184e;
}
.icon-heart:before {
  content: "\e900";
  color: #fff;
}
.icon-asia-pacific-regions-icon:before {
  content: "\e901";
}
.icon-south-east-asia-regions-icon:before {
  content: "\e902";
}
.icon-asia-regions-icon:before {
  content: "\e903";
}
.icon-middle-east-regions-icon:before {
  content: "\e904";
}
.icon-europe-regions-icon:before {
  content: "\e905";
}
.icon-americas-regions-icon:before {
  content: "\e906";
}
.icon-folder-download:before {
  content: "\e933";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
