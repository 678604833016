body {
  font-size: 16px; 
  font-family: 'museo-sans-rounded', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; 
}

h1,
h2,
.font-weight-1 { 
  font-weight: 300; 
}

p,
h3,
h4,
.font-weight-2 { 
  font-weight: 500; 
}

a,
.btn,
button,
.font-weight-3 { 
  font-weight: 600; 
}

p { word-wrap: break-word; }

.bump { font-size: 1.25rem; }
.sub { font-size: .875rem; }
