a {
  color: inherit;
  text-decoration: none;
}

p a {
  transition: color 400ms ease;
  color: var(--blue);
  text-decoration: underline;
}

p a:hover {
  color: var(--orange);
}
