.sidebar-narrow {
  width: 240px;
  padding-right: 2rem;
}
.sidebar-wide { padding-left: 240px; }
.search-button--wide { display: none; }
.search-input { display: block; }
.sidebar-radio { width: 100%; }

@media (max-width: 800px) {
  .search-button--narrow { display: none; }
  .sidebar-radio {
    float: left;
    width: 50%; 
  }
  .sidebar-narrow { 
    padding-right: 0;
    width: 100%;
    position: relative;
  }
  .sidebar-wide { padding-left: 0; }
  .search-input,
  .search-button--wide { 
    display: table-cell; 
  }
  .search-input { padding-right: 1rem; }
}
