.scrim-grad { background: linear-gradient(0deg, rgba(0,0,0,0.4),transparent); }
.grad-overlay { position: relative; }
.grad-overlay:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .4;
  background-image: linear-gradient(-134deg,#00abaf 0,#1e184e 100%);
}

.transition:after,
.transition:before,
.transition {
  transition: all 200ms ease;
  transform: translatez(0);
}

.fade-in {
  animation-name: fadein;
  animation-duration: 400ms;
}

@keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

blockquote {
  padding: 0 .5em 0 1em;
  border-left: 3px solid var(--teal);
  font-size: 1rem;
  margin: 2rem 0;
}

.quote { position: relative; }

.quote:before,
.quote:after {
  line-height: 1;
  position: absolute;
  font-size: 6rem;
  opacity: 0.25;
}

.quote:before {
  content: '“';
  top: -1rem;
  left: -1rem;
  vertical-align: top;
}
.quote:after {
  content: '”';
  bottom: -1rem;
  right: -1rem;
  vertical-align: bottom;
}


.overlayBox {
  position: absolute;
  right: 0;
  bottom: 2rem;
  width: 50%;
}

.overlayBox-container {
  padding: 1rem;
  margin: 0 auto;
  max-width: 75rem;
}

.collapsed { 
  overflow: hidden;
  max-height: 0; 
}

.collapsed-open { max-height: 400px; }

.responsive-youtube {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.responsive-youtube iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.hover-shadow:hover {
  box-shadow: var(--shadow-2);
}
@media (min-width: 75rem) {
  .overlayBox {
    padding: 2rem;
    bottom: 0; 
    width: 100%;
  }
}

@media (max-width: 45rem) {
  .overlayBox {
    bottom: 0; 
    width: 100%;
  }
}
