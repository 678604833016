:root {
  --black: #232323;
  --grey: #ECECEC;
  --blue: #1E184E;
  --blueLight:#D4EDEC;
  --teal : #0495A0;
  --purple: #595893;
  --purpleOpacity: rgba(30,24,78,.51);
  --orange : #CC4D29;
  --ochre: #c8b92e;
}

/* text color rules */
.color-black { color: var(--black); }
.color-grey { color: grey; }
.color-white { color: white; }
.color-blue { color: var(--blue); }
.color-blueLight { color: var(--blueLight); }
.color-teal { color: var(--teal); }
.color-purple { color: var(--purple); }
.color-orange { color: var(--orange); }


/* background color rules */
.bg-black { background-color: var(--black); }
.bg-grey { background-color: var(--grey); }
.bg-white { background-color: white; }
.bg-blue { background-color: var(--blue); }
.bg-blueLight { background-color: var(--blueLight); }
.bg-teal { background-color: var(--teal); }
.bg-purple { background-color: var(--purple); }
.bg-purpleOpacity { background-color: var(--purpleOpacity); }
.bg-orange { background-color: var(--orange); }

.bg-blue-grad {
  background-image: linear-gradient(242deg,#403586 0,#392f7b 19%,#1e184e 100%);
}
.bg-whiteFade {
  background-color: rgba(255,255,255,0.85);
}

.border-color-purple { border-color: var(--purple); }
.border-color-blue { border-color: var(--blue); }
.border-color-blueLight { border-color: var(--blueLight); }
.border-color-white { border-color: white;}
.border-color-teal { border-color: var(--teal);}
.border-color-ochre { border-color: var(--ochre); }

.hover-teal { 
  transition: color 200ms ease;
  transform: translatez(0);
}

.hover-teal:hover { color: var(--teal); }

.hover-bg-blue:hover {
  background-color: var(--blue);
}
