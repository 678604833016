.card button {
  opacity: 0;
  visibility: hidden;
}
.card-imageWrapper { position: relative; }
.card-imageWrapper:after { 
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background: transparent;
}

.card:hover {
  box-shadow: var(--shadow-1);
}
.card:hover .card-imageWrapper:after { 
  background: var(--teal);
}

.card:hover .card-imageMask {
  background: var(--purple);
  opacity: 0.5;
}
.card:hover button {
  opacity: 1;
  visibility: visible;
}
.card:hover .card-image { filter: blur(1px); }
.card:hover .card-region { color: var(--teal); }
.card:hover .card-title { color: var(--orange); }
